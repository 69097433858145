import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { TransactionRequest, TransactionResponse, OCRResponse, TransactionSearchParams, TransactionAcceptanceStatusRequest, TransactionAcceptanceStatus, TransactionStatusUpdateRequest, TransactionType, InvoiceAnswerRequest } from '../models/transaction';
import { OrderOptions, Keys } from '../constants/keys';
import { BaseService, HttpClientService } from './base.service';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';
import { IsExpenseTransactionExistsRequest } from '../requests/accounting/is-expense-transaction-exists-request';
import { catchError, map } from 'rxjs/operators';
import { WithholdingTaxDefinition } from '../models/tax/withholding-tax-definition';
import { InvoiceTypeCodeEnum } from '../../../enums/invoice-type-code-enum';
import { VATOption } from '../models/VATOption';

const Endpoints = {
    Controller: 'users/{id}/transactions',
    Transactions: 'users/{id}/transactions?type={type}&startDate={startDate}&endDate={endDate}&page={page}&size={size}&q={q}&sort_by={sort_by}&order_by={order_by}',
    AdminTransactions: 'users/{id}/transactions?type={type}&isSentEDefter={isSentEDefter}&status={status}&page={page}&size={size}&startDate={startDate}&endDate={endDate}&sort_by={sort_by}&order_by={order_by}',
    AdminTransactionsEdit: 'transactions/users/{id}/edit?type={type}&isSentEDefter={isSentEDefter}&page={page}&size={size}&startDate={startDate}&endDate={endDate}&sort_by={sort_by}&order_by={order_by}',
    Transaction: 'users/{userId}/transactions/{id}',
    Excel_Export: 'users/{mainUserId}/transactions/zirve-export?startDate={startDate}&endDate={endDate}&type={type}',
    Image_Upload: 'users/{userId}/transactions/{id}/image',
    OCR: 'users/{mainUserId}/transactions/ocr',
    EInvoice: 'users/{mainUserId}/transactions/{id}/invoice',
    EInvoicePreview: 'users/{mainUserId}/transactions/{id}/invoice-preview',
    CheckCompany: 'users/{mainUserId}/transactions/{id}/check-company',
    CompleteCompanyMissingData: 'users/{mainUserId}/transactions/{transactionId}/complete-company-missing-data',
    EInvoiceStatus: 'users/{mainUserId}/transactions/{id}/invoice/status',
    EInvoice_Pdf: 'users/{mainUserId}/transactions/{transactionId}/pdf',
    ExpenseImportDocument: 'users/{mainUserId}/transactions/{transactionId}/document',
    ImportFromExcel: 'transactions/excel?companyId={companyId}',
    ImportFromIETransactionsExcel: 'transactions/excel-ie?ownerId={ownerId}&transactionType={transactionType}',
    SearchTransactions: 'transactions?page={page}&size={size}&sort_by={sort_by}&order_by={order_by}&',
    Admin_Excel_Export: 'transactions/excel?',
    ById: 'transactions/{id}',
    ByIdStatus: 'transactions/{id}/status',
    AnswerInvoice: 'users/{mainUserId}/transactions/{transactionId}/answer-invoice',
    AcceptAllInvoices: 'users/{mainUserId}/accept-all-invoices',
    RejectSelectedEInvoices: 'users/{mainUserId}/reject-selected-invoices',
    AcceptSelectedInvoices: 'users/{mainUserId}/accept-selected-invoices',
    SetCompanyOfTransaction: 'users/{mainUserId}/transactions/{transactionId}/set-company/{companyId}',
    TransactionReturnInvoice: 'users/{UserId}/transactions/{TransactionId}/return',
    WitholdingTaxDefinitions: 'tax/list-withholding-tax-definitions',
    IsExpenseTransactionExists: 'transactions/is-expense-invoice-exists',
    VATOptions: 'i18n/enum-values/vat-rate-Type',
    InvoiceDelete: 'user/{mainUserId}/invoice/{transactionId}',
    NewTransactionGet: 'edefter/ledger-declaration-transactions/{mainUserId}?type={type}&isSentEDefter={isSentEDefter}&status={status}&page={page}&size={size}&startDate={startDate}&endDate={endDate}&sort_by={sort_by}&order_by={order_by}&ledgerDeclarationStatus={ledgerDeclarationStatus}'
};

@Injectable()
export class TransactionService extends BaseService<TransactionRequest, TransactionResponse> {

    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    searchTransactions(
        page: number = 0,
        size: number = 30,
        sort_by: any = '',
        order_by: string = OrderOptions.Default,
        params = new TransactionSearchParams()
    ): Observable<PaginatedResponseWrapperOvi<TransactionResponse>> {
        const json = {
            page: page,
            size: size,
            sort_by: sort_by,
            order_by: order_by,
        };
        let fullPath = this.createUrlWithParams(Endpoints.SearchTransactions, json);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        return this.client.get<PaginatedResponseWrapperOvi<TransactionResponse>>(fullPath);
    }

    getTransactions(
        type: string,
        startDate: any,
        endDate: any,
        page: number = 0,
        size: number,
        q: string = '',
        sort_by: any = '',
        order_by: string = OrderOptions.Desc,
        userId: string = localStorage.getItem(Keys.USER_ID),
    ): Observable<PaginatedResponseWrapperOvi<TransactionResponse>> {

        const json = {
            id: userId,
            type: type,
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
            page: page,
            size: size,
            q: q,
            sort_by: sort_by,
            order_by: order_by,
        };

        const fullPath = this.createUrlWithParams(Endpoints.Transactions, json);
        return this.client.get<PaginatedResponseWrapperOvi<TransactionResponse>>(fullPath);
    }

    getAdminTransactions(
        type: string,
        page: number = 0,
        size: number = 30,
        userId: string,
        isSentEDefter: boolean,
        startDate: string = null,
        endDate: string = null,
        sortBy: string = null,
        order_by: string = OrderOptions.Desc,
    ): Observable<PaginatedResponseWrapperOvi<TransactionResponse>> {

        const json = {
            id: userId,
            type: type,
            page: page,
            size: size,
            isSentEDefter: isSentEDefter,
            startDate: startDate,
            endDate: endDate,
            sort_by: sortBy,
            order_by: order_by,
            status: 'Completed'
        };

        const fullPath = this.createUrlWithParams(Endpoints.AdminTransactions, json);
        return this.client.get<PaginatedResponseWrapperOvi<TransactionResponse>>(fullPath);
    }

    getAdminTransactionsEdit(
        agentId,
        type: string,
        page: number = 0,
        size: number = 30,
        isSentEDefter: boolean,
        startDate: string = null,
        endDate: string = null,
        sortBy: string = null,
        order_by: string = OrderOptions.Desc,

    ): Observable<PaginatedResponseWrapperOvi<TransactionResponse>> {
        const json = {
            id: agentId,
            type: type,
            page: page,
            size: size,
            isSentEDefter: isSentEDefter,
            startDate: startDate,
            endDate: endDate,
            sort_by: sortBy,
            order_by: order_by,
        };
        const fullPath = this.createUrlWithParams(Endpoints.AdminTransactionsEdit, json);
        return this.client.get<PaginatedResponseWrapperOvi<TransactionResponse>>(fullPath);
    }

    getTransaction(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<TransactionResponse>> {
        const fullPath = this.createUrlWithParams(Endpoints.Transaction, { userId: userId, id: transactionId });
        return this.client.get<BaseResponseWrapper<TransactionResponse>>(fullPath).pipe(
            map((response) => {
                response.data.onDate = new Date(response.data.onDate);
                if (response.data.returnTransaction != null)
                    response.data.returnTransaction.invoiceDate = new Date(response.data.returnTransaction.invoiceDate);

                response.data.invoiceType = InvoiceTypeCodeEnum[response.data.invoiceType];
                // console.log([typeof(InvoiceTypeCodeEnum.SATIS), InvoiceTypeCodeEnum.SATIS]);
                // console.log([typeof(response.data.invoiceType), response.data.invoiceType]);
                // console.log([typeof(InvoiceTypeCodeEnum[response.data.invoiceType]), InvoiceTypeCodeEnum[response.data.invoiceType]]);

                // response.data.profileId = 

                return response;
            }),
            catchError((r) => { return null; }));



        // .map(response => {
        //     return response;
        // }).catch((r) => { return null; });
        // .map((response) => {
        //     // response.data.onDate = new Date(response.data.onDate);
        //     if (response.data.returnTransaction != null)
        //         response.data.returnTransaction.invoiceDate = new Date(response.data.returnTransaction.invoiceDate);
        //     return response;
        // });
    }

    getExcelExport(startDate: any, endDate: any, type: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<any> {

        const json = {
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
            type: type,
            mainUserId: userId,
        };

        const fullPath = this.createUrlWithParams(Endpoints.Excel_Export, json);
        return this.client.get(fullPath, true);
    }

    getAdminExcelExport(
        params = new TransactionSearchParams()
    ): Observable<any> {
        let fullPath = this.createUrlWithParams(Endpoints.Admin_Excel_Export);
        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
        return this.client.get(fullPath, true);
    }

    createEInvoice(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID), externalReference?: string, reIssue?: boolean, useOnDate?: boolean): Observable<BaseResponseWrapper<TransactionResponse>> {
        let url = this.createUrlWithParams(Endpoints.EInvoice, {
            id: transactionId,
            mainUserId: userId,
        });

        const queryStringParameters = {
            externalReference,
            reIssue,
            useOnDate
        };
        const keys = Object.keys(queryStringParameters);
        const queryString = keys.filter(x => typeof (queryStringParameters[x]) !== "undefined" && queryStringParameters[x] !== null).map(x => `${x}=${queryStringParameters[x]}`).join("&");
        if (queryString.trim() !== "")
            url = `${url}?${queryString}`;

        console.log(url);

        return this.client.post<BaseResponseWrapper<TransactionResponse>>(url);
    }

    eInvoicePreview(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<any>> {
        const url = this.createUrlWithParams(Endpoints.EInvoicePreview, {
            id: transactionId,
            mainUserId: userId,
        });

        return this.client.post<BaseResponseWrapper<any>>(url);
    }

    checkCompany(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<any[]> {
        const url = this.createUrlWithParams(Endpoints.CheckCompany, {
            id: transactionId,
            mainUserId: userId,
        });

        return this.client.get<any[]>(url);
    }

    checkInvoiceStatus(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<TransactionResponse>> {
        const url = this.createUrlWithParams(Endpoints.EInvoiceStatus, {
            id: transactionId,
            mainUserId: userId,
        });

        return this.client.get<BaseResponseWrapper<TransactionResponse>>(url);
    }

    rejectTransaction(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)) {
        const url = this.createUrlWithParams(Endpoints.Transaction, { id: transactionId, userId: userId });

        const request = new TransactionAcceptanceStatusRequest;
        request.acceptanceStatus = TransactionAcceptanceStatus.Rejected;

        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(request));
    }


    getEInvoicePDF(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)) {
        const json = {
            transactionId: transactionId,
            mainUserId: userId,
        };

        const fullPath = this.createUrlWithParams(Endpoints.EInvoice_Pdf, json);
        return this.client.get(fullPath, true);
    };

    importExpenseDocumentPDF(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID), file: File) {
        const dataToPost = new FormData();
        dataToPost.append("file", file, file.name);

        const fullPath = this.createUrlWithParams(Endpoints.ExpenseImportDocument, { mainUserId: userId, transactionId: transactionId });

        return this.client.postMultiPart<BaseResponseWrapper<string>>(fullPath, dataToPost);
    };
    createTransaction(request: TransactionRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<TransactionResponse>> {
        const url = this.createUrlWithParams(Endpoints.Controller, { id: userId });

        const json = {
            ...request,
            onDate: request.onDate.toISOString(),
        };

        return this.client.post<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };

    updateTransaction(transactionId: string, request: TransactionRequest, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<TransactionResponse>> {
        const url = this.createUrlWithParams(Endpoints.Transaction, { id: transactionId, userId: userId });

        const json = {
            ...request,
            onDate: request.onDate.toISOString(),
        };

        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };

    updateTransactionByAdmin(transactionId: string, request: any): Observable<BaseResponseWrapper<TransactionResponse>> {
        const url = this.createUrlWithParams(Endpoints.ById, { id: transactionId });
        const json = {
            ...request,
        };

        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };

    updateTransactionStatusByAdmin(transactionId: string, request: TransactionStatusUpdateRequest): Observable<BaseResponseWrapper<TransactionResponse>> {
        const url = this.createUrlWithParams(Endpoints.ById, { id: transactionId });
        const json = {
            ...request,
        };

        return this.client.put<BaseResponseWrapper<TransactionResponse>>(url, JSON.stringify(json));
    };

    deleteTransaction(transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<any>> {
        const url = this.createUrlWithParams(Endpoints.Transaction, { id: transactionId, userId: userId });
        return this.client.delete<BaseResponseWrapper<any>>(url);
    };

    deleteTransactionByAdmin(transactionId: string): Observable<BaseResponseWrapper<any>> {
        const url = this.createUrlWithParams(Endpoints.ById, { id: transactionId });
        return this.client.delete<BaseResponseWrapper<any>>(url);
    };

    uploadImage(transactionId: string, formData: FormData, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<any>> {
        const fullPath = this.createUrlWithParams(Endpoints.Image_Upload, { id: transactionId, userId: userId });
        return this.client.postMultiPart<BaseResponseWrapper<any>>(fullPath, formData);
    };

    ocr(formData: FormData, userId: string = localStorage.getItem(Keys.USER_ID)): Observable<BaseResponseWrapper<OCRResponse>> {
        const fullPath = this.createUrlWithParams(Endpoints.OCR, { mainUserId: userId });
        return this.client.postMultiPart<BaseResponseWrapper<OCRResponse>>(fullPath, formData);
    };

    uploadExcel(formData: FormData, companyId: string): Observable<BaseResponseWrapper<any>> {
        const fullPath = this.createUrlWithParams(Endpoints.ImportFromExcel, { companyId: companyId });
        return this.client.postMultiPart<BaseResponseWrapper<any>>(fullPath, formData);
    }
    uploadIETransactionsExcel(formData: FormData, ownerId: string, transactionType: TransactionType) {
        const fullPath = this.createUrlWithParams(Endpoints.ImportFromIETransactionsExcel, { ownerId: ownerId, transactionType: transactionType });
        return this.client.postMultiPart<BaseResponseWrapper<any>>(fullPath, formData);
    }

    answerEInvoice(request: InvoiceAnswerRequest, transactionId: string, userId: string = localStorage.getItem(Keys.USER_ID)) {
        const url = this.createUrlWithParams(Endpoints.AnswerInvoice, {
            transactionId: transactionId,
            mainUserId: userId,
        });

        return this.client.post(url, JSON.stringify(request));
    }

    acceptAllEInvoices(userId: string = localStorage.getItem(Keys.USER_ID)) {
        const url = this.createUrlWithParams(Endpoints.AcceptAllInvoices, {
            mainUserId: userId,
        });

        return this.client.post(url);
    }

    acceptSelectedEInvoices(transactions: TransactionResponse[]) {
        const userId: string = localStorage.getItem(Keys.USER_ID);
        const transactionIds = transactions != null ? transactions.map(t => t.id) : [];
        const url = this.createUrlWithParams(Endpoints.AcceptSelectedInvoices, { mainUserId: userId });
        return this.client.post(url, JSON.stringify(transactionIds));
    }

    rejectSelectedEInvoices(transactions: TransactionResponse[]) {
        const userId: string = localStorage.getItem(Keys.USER_ID);
        const transactionIds = transactions != null ? transactions.map(t => t.id) : [];
        const url = this.createUrlWithParams(Endpoints.RejectSelectedEInvoices, { mainUserId: userId });
        return this.client.post(url, JSON.stringify(transactionIds));
    }

    completeCompanyMissingData(transaction: TransactionResponse, completeObj: any) {
        const userId: string = localStorage.getItem(Keys.USER_ID);
        const url = this.createUrlWithParams(Endpoints.CompleteCompanyMissingData, { mainUserId: userId, transactionId: transaction.id });
        return this.client.patch(url, JSON.stringify(completeObj));
    }

    setCompanyOfTransaction(transaction: TransactionResponse, companyId) {
        const userId: string = localStorage.getItem(Keys.USER_ID);
        const url = this.createUrlWithParams(Endpoints.SetCompanyOfTransaction, { mainUserId: userId, transactionId: transaction.id, companyId: companyId });
        return this.client.put(url);
    }

    transactionReturnInvoice(ownerId: string, transactionId: string, reasonStr: string) {

        const url = this.createUrlWithParams(Endpoints.TransactionReturnInvoice, {
            TransactionId: transactionId,
            UserId: ownerId,
        });

        return this.client.post(url, JSON.stringify({ reason: reasonStr }));
    }

    async getWitholdingTaxDefinitions(): Promise<WithholdingTaxDefinition[]> {
        const fullPath = this.createUrlWithParams(Endpoints.WitholdingTaxDefinitions);
        const requestResult: Observable<BaseResponseWrapper<WithholdingTaxDefinition[]>> = this.client.get<BaseResponseWrapper<WithholdingTaxDefinition[]>>(fullPath);


        const result: Promise<WithholdingTaxDefinition[]> = new Promise((resolve, reject) => {
            requestResult.subscribe({
                next: response => {
                    response.data.forEach(o => {
                        o.displayTitle = o.code !== null ? `${o.code}: ${o.title} ${o.rateLabel}` : `${o.title} ${o.rateLabel}`;
                    });
                    resolve(response.data);
                }, error: response => {
                    reject(response);
                }
            });
        });

        return result;
    };

    async isExpenseTransactionExistsAsync(taxNo: string, invoiceNo: string): Promise<boolean> {

        const request: IsExpenseTransactionExistsRequest = new IsExpenseTransactionExistsRequest();
        request.invoiceNo = invoiceNo;
        request.taxNo = taxNo;

        const fullPath = this.createUrlWithParams(Endpoints.IsExpenseTransactionExists);
        const requestResult = this.client.post<BaseResponseWrapper<boolean>>(fullPath, request)
        const result: Promise<boolean> = new Promise((resolve, reject) => {
            requestResult.subscribe({
                next: response => {
                    resolve(response.data);
                }, error: response => {
                    resolve(false);
                }
            });
        });


        // const result:BaseResponseWrapper<boolean> = await this.client.post<BaseResponseWrapper<boolean>>(fullPath, request).toPromise();
        return result;
    }

    public getVAtOptions() {
        let fullPath = `${this.BASE_API_URL + Endpoints.VATOptions}`;
        return this.client.get(fullPath);
    }
    invoiceCanceled(_transactionId: string): Observable<BaseResponseWrapper<any>> {
        const _userId = localStorage.getItem(Keys.USER_ID);
        const fullPath = this.createUrlWithParams(
            Endpoints.InvoiceDelete,
            {
                mainUserId: _userId,
                transactionId: _transactionId
            });

        return this.client.delete<BaseResponseWrapper<any>>(fullPath);
    }

    getNewTransactions(
        type: string,
        page: number = 0,
        size: number = 30,
        userId: string,
        isSentEDefter: boolean,
        startDate: string = null,
        endDate: string = null,
        sortBy: string = null,
        order_by: string = OrderOptions.Desc,
        _ledgerDeclarationStatus:number ,
    ): Observable<PaginatedResponseWrapperOvi<TransactionResponse>> {
        const json = {
            mainUserId:userId,
            type: type,
            page: page,
            size: size,
            isSentEDefter: isSentEDefter,
            startDate: startDate,
            endDate: endDate,
            sort_by: sortBy,
            order_by: order_by,
            status: 'Completed',
            ledgerDeclarationStatus: _ledgerDeclarationStatus
        };

        const fullPath = this.createUrlWithParams(Endpoints.NewTransactionGet, json);
        return this.client.get<PaginatedResponseWrapperOvi<TransactionResponse>>(fullPath);
    }

}
