import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { BaseService, HttpClientService } from './base.service';
import { CourierCompanyiLstResponse, KeyValueResponse, SenderTags, UserResponse, UserSearchParams, UserUpdateByAdminRequest } from '../models/user';
import { Keys, OrderOptions } from '../constants/keys';
import { PaginatedResponseWrapperOvi, BaseResponseWrapper } from '../models/api-response-wrappers';
import { map } from 'rxjs-compat/operators/map';
import { catchError } from 'rxjs-compat/operators/catchError';
import { PrivateIntegratorEnum } from '../../../enums/private-integrator-enum';

const Endpoints = {
    Controller: 'users',
    SearchUsers: 'users?page={page}&size={size}&sort_by={sort_by}&order_by={order_by}&dontInclude={dontInclude}&',
    SearchCustomerUsers: 'users/search-customer?page={page}&size={size}&sort_by={sort_by}&order_by={order_by}&',
    Excel_Export: 'users/excel-export?',
    Excel_PaymentExport: 'Payments/Report?',
    User: 'users/{id}?includeRelations={includeRelations}',
    AddPayment: 'users/addPayment',
    Shutdown: 'users/me/shutdown?shutdownDate={shutdownDate}',
    ResetPassword: 'users/{id}/reset-password?newPassword={newPassword}',
    IsCustomer: 'users/{id}/is-customer',
    IsAdvisor: 'users/{id}/is-advisor',
    Agent: 'users/agent',
    ContractUpload: 'contracts/send-contracts-for-customer-acceptence/{userId}',
    ExcelImport: 'users/excel-import',
    UserContracts: 'contracts/get-contracts-of-the-user/{userId}',
    TaxPlate: 'ivd/user/get-tax-plate-url/{userId}',
    ReconciliationSchemaSave: 'users/save-reconciliation-schema',
    ReconciliationSchemaGet: 'users/get-reconciliation-schema/{schemaKey}',
    ReconciliationGet: 'users/get-reconciliation',
    ReconciliationImport: 'users/import-reconciliation',
    ReconciliationList: 'users/list-reconciliation/{key}',
    // ReconciliationSchemaList: 'users/list-reconciliation-schema',
    ReconciliationProgressPaymentCreate: 'courier/reconciliation/progress-payment',
    ReconciliationIntermPaymentListByKey: 'users/list-reconciliation-interim-payment/{schemaKey}',
    ReconciliationIntermPaymentList: 'users/list-reconciliation-interim-payment',
    ReconciliationSave: 'courier/reconciliation',
    ReconciliationSendNotification: 'users/send-reconciliation-notifications/{key}',
    ReconciliationDelete: 'courier/reconciliation/{key}',
    InterimPaymentDelete: 'courier/reconciliation/progress-payment',
    UserCheckToken: 'users/check-token',
    UserDocumentTypesList: 'user/documentType/list',
    UserDocumentsDeleteById: 'user/documentUsers/delete/{userId}/{documentTypeId}',
    UserDocumentUser: 'user/documentUser/{userId}',
    UserDocumentsSave: 'user/documentUsers/save/{userId}/{documentTypeId}',
    ActAsUser: 'account/act-as/{userId}',
    PairExternalId: "user/pair-external-id/{companyId}",
    CourierCompaniesList: "user/courier_companies_cbox_list",//Şirket DDL
    UserCourierCompanyInsert: "user/user_courier_company",//Insert
    UserCourierCompanyUpdate: "user/user_courier_company", //Update
    UserCourierCompanyDelete: "user/user_courier_company/{id}",//Delete
    UserCourierCompanyMultipleGet: "user/user-courier-companies/{userId}",//user'a ait Kayıtlar
    UserCourierCompanySingleGet: "user/user_courier_company/{id}",//Tek Kayıt
    RemoveAdvisor: "users/{id}/remove-advisor",
    CreateETransformationAccount: "users/create-e-transformation-account/{userId}",
    AgentReport: "users/agentreport?monthFilter={monthFilter}&isActive={isActive}",
    AgentExcelExport: 'users/agentreport-excel?',

    UserByTaxNo: 'users/by-taxNo/{taxNo}?agentId={agentId}'

};

@Injectable()
export class UserService extends BaseService<any, UserResponse> {
    constructor(httpClient: HttpClientService) {
        super(httpClient, Endpoints.Controller);
    }

    userCheckToken() {
        const fullPath = this.createUrlWithParams(Endpoints.UserCheckToken);
        return this.client.get<BaseResponseWrapper<UserResponse>>(fullPath);
    }

    resetPasswordByAdmin(id: string, newPassword: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ResetPassword, { id: id, newPassword: newPassword });
        return this.client.post<BaseResponseWrapper<any>>(fullPath);
    }

    updateUserByAdmin(userId: string, request: UserUpdateByAdminRequest) {
        const fullPath = this.createUrlWithParams(Endpoints.User, { id: userId, includeRelations: false });
        return this.client.put<BaseResponseWrapper<any>>(fullPath, JSON.stringify(request));
    }

    searchUsers(
        page: number = 0,
        size: number = 30,
        sort_by: any = 'id',
        order_by: string = OrderOptions.Desc,
        params = new UserSearchParams(),
        dontInclude = false,
        IsNewCustomer = false
    ): Observable<PaginatedResponseWrapperOvi<UserResponse>> {
        const json = {
            page: page,
            size: size,
            sort_by: sort_by,
            order_by: order_by,
            dontInclude: dontInclude,
            IsNewCustomer: IsNewCustomer
        };
        let fullPath = this.createUrlWithParams(Endpoints.SearchUsers, json);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        fullPath += "&IsNewCustomer=" + IsNewCustomer + "&dontInclude=" + dontInclude;

        return this.client.get<PaginatedResponseWrapperOvi<UserResponse>>(fullPath);
    }
    searchCustomerUsers(
        page: number = 0,
        size: number = 0,
        sort_by: any = 'id',
        order_by: string = OrderOptions.Desc,
        params = new UserSearchParams(),
    ): Observable<PaginatedResponseWrapperOvi<UserResponse>> {
        const json = {
            page: page,
            size: size,
            sort_by: sort_by,
            order_by: order_by,
        };
        let fullPath = this.createUrlWithParams(Endpoints.SearchCustomerUsers, json);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        return this.client.get<PaginatedResponseWrapperOvi<UserResponse>>(fullPath);
    }

    setIsCustomer(id: string, isCustomer: boolean) {
        const fullPath = this.createUrlWithParams(Endpoints.IsCustomer, { id: id });
        const request = JSON.stringify({ isCustomer });
        return this.client.put<BaseResponseWrapper<any>>(fullPath, request);
    }

    setIsAdvisor(id: string, isAdvisor: boolean) {
        const fullPath = this.createUrlWithParams(Endpoints.IsAdvisor, { id: id });
        const request = JSON.stringify({ isAdvisor });
        return this.client.put<BaseResponseWrapper<any>>(fullPath, request);
    }

    getUserWithId(userId: string, includeRelations: boolean): Observable<BaseResponseWrapper<UserResponse>> {
        const fullPath = this.createUrlWithParams(Endpoints.User, { id: userId, includeRelations });

        return this.client.get<BaseResponseWrapper<UserResponse>>(fullPath).pipe(
            map((response) => {
                response.data.privateIntegrator = PrivateIntegratorEnum[response.data.privateIntegrator];
                return response;
            }),
            catchError((r) => { return null; }));;
    }

    shutdownUserAccount(shutdownDate: Date): Observable<BaseResponseWrapper<any>> {
        const fullPath = this.createUrlWithParams(Endpoints.Shutdown, { shutdownDate: shutdownDate.toISOString() });
        return this.client.post<BaseResponseWrapper<any>>(fullPath);
    }

    getExcelExport(
        params = new UserSearchParams()
    ): Observable<any> {
        let fullPath = this.createUrlWithParams(Endpoints.Excel_Export);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
        return this.client.get(fullPath, true);
    }

    getExcelPaymentExport(
        params = new UserSearchParams()
    ): Observable<any> {
        let fullPath = this.createUrlWithParams(Endpoints.Excel_PaymentExport);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
        return this.client.get(fullPath, true);
    }

    agent(): Observable<KeyValueResponse[]> {
        const fullPath = this.createUrlWithParams(Endpoints.Agent);
        return this.client.get<KeyValueResponse[]>(fullPath);
    }

    getTaxPlateUrl(userId: string): Observable<any> {
        const json = {
            userId: userId
        };
        const fullPath = this.createUrlWithParams(Endpoints.TaxPlate, json);
        return this.client.get<string>(fullPath);
    }

    async actAsUser(userId: string): Promise<string> {
        const json = {
            userId: userId
        };
        const fullPath = this.createUrlWithParams(Endpoints.ActAsUser, json);
        const response: BaseResponseWrapper<string> = await this.client.post<BaseResponseWrapper<string>>(fullPath).toPromise();
        return response.data;
    }

    addPayment(userId: string, year: number, month: number, searchParams: UserSearchParams): Observable<any> {

        let fullPath = this.createUrlWithParams(Endpoints.AddPayment + "?year=" + year + "&month=" + month + "&");

        Object.keys(searchParams).forEach(x => {
            if (searchParams[x] !== undefined && searchParams[x] !== null) {
                const qParam = `${x}=${searchParams[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);

        return this.client.get<string>(fullPath);
    }
    uploadContracts(request: any, userId: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ContractUpload, { userId: userId });
        return this.client.put<BaseResponseWrapper<any>>(fullPath, JSON.stringify(request));
    }


    uploadBulkCustomers(request: File) {
        const dataToPost = new FormData();
        dataToPost.append("file", request, request.name);
        const fullPath = this.createUrlWithParams(Endpoints.ExcelImport);
        return this.client.postMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
    }


    userContracts(userId: string) {

        const fullPath = this.createUrlWithParams(Endpoints.UserContracts, { userId: userId });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    userDetailContracts(userId) {
        const fullPath = this.createUrlWithParams(Endpoints.UserContracts, { userId: userId });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationSchemaGet(schemaKey: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationSchemaGet, { schemaKey: schemaKey });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationSchemaSave(schema: any[], schemaName: string, schemaKey?: string): Observable<BaseResponseWrapper<any>> {

        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationSchemaSave);
        const json = {
            columns: schema,
            name: schemaName,
            key: schemaKey
        };
        return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
    }

    reconciliationGet() {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationGet);
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationList(interimPaymentKey: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationList, { key: interimPaymentKey });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationImport(interimPaymentKey: string, schemaKey: string, importedData: any[]): Observable<BaseResponseWrapper<any>> {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationImport);
        const json = {
            schemaKey: schemaKey,
            progressPaymentKey: interimPaymentKey,
            data: importedData
        };
        return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
    }

    // Moved to CourierReconciliationService
    // getSchemaList() {
    //     const fullPath = this.createUrlWithParams(Endpoints.ReconciliationSchemaList);
    //     return this.client.get<BaseResponseWrapper<any>>(fullPath);
    // }


    reconciliationProgressPaymentSave(schemaKey: string, name?: string, key?: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationProgressPaymentCreate);
        const keyExists: boolean = key !== null && key.trim() !== "";
        const json = {
            schemaKey: schemaKey,
            key: key,
            name: name
        };
        return keyExists ? this.client.patch<any>(fullPath, JSON.stringify(json)) : this.client.post<any>(fullPath, JSON.stringify(json));
    }

    reconciliationIntermPaymentListByKey(schemaKey: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationIntermPaymentListByKey, { schemaKey: schemaKey });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationIntermPaymentList() {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationIntermPaymentList);
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    reconciliationSave(reconciliation: any) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationSave);
        return this.client.patch<any>(fullPath, JSON.stringify(new Array(reconciliation)));
    }

    reconciliationSendNotification(interimPaymentKey: string) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationSendNotification, { key: interimPaymentKey });
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }


    reconciliationDelete(reconciliation: any) {
        const fullPath = this.createUrlWithParams(Endpoints.ReconciliationDelete, { key: reconciliation.key });
        return this.client.delete<boolean>(fullPath);
    }

    deleteProgressPayment(key): Observable<BaseResponseWrapper<boolean>> {
        const fullPath = this.createUrlWithParams(Endpoints.InterimPaymentDelete);
        return this.client.delete<BaseResponseWrapper<boolean>>(fullPath, JSON.stringify(new Array(key)));
    }

    documentTypesList() {
        const fullPath = this.createUrlWithParams(Endpoints.UserDocumentTypesList);
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    userDocumentsDeleteById(userId: string, documentId: string) {
        const fullPath = this.createUrlWithParams(Endpoints.UserDocumentsDeleteById, { userId: userId, documentTypeId: documentId });
        return this.client.post<BaseResponseWrapper<any>>(fullPath);
    }

    userDocumentUser(userId: string) {
        const json = {
            userId: userId
        };
        const fullPath = this.createUrlWithParams(Endpoints.UserDocumentUser, json);
        return this.client.get<string>(fullPath);
    }

    documentUsersSave(requestFile: any, userId: string) {

        if (requestFile.documentType == "IMAGE") {
            const dataToPost = new FormData();
            dataToPost.append("image", requestFile.file, requestFile.file.name);
            const fullPath = this.createUrlWithParams(Endpoints.UserDocumentsSave, { userId: userId, documentTypeId: requestFile.documentTypeId });
            return this.client.postMultiPart<BaseResponseWrapper<string>>(fullPath, dataToPost);
        } else if (requestFile.documentType == "TEXT") {
            var json = { text: requestFile.txt }
            const fullPath = this.createUrlWithParams(Endpoints.UserDocumentsSave, { userId: userId, documentTypeId: requestFile.documentTypeId });
            return this.client.post<BaseResponseWrapper<any>>(fullPath, JSON.stringify(json));
        }
    }
    // pairExternalExport(request: any): Observable<any> {
    //     const dataToPost = new FormData();
    //     dataToPost.append("file", request, request.name);
    //     const fullPath = this.createUrlWithParams(Endpoints.PairExternalId);
    //     return this.client.putMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
    // }
    pairExternalExport(request: any, _companyId: number): Observable<any> {
        const dataToPost = new FormData();
        // dataToPost.append('companyId', _companyId.toString());
        dataToPost.append("file", request, request.name);
        const fullPath = this.createUrlWithParams(Endpoints.PairExternalId, { companyId: _companyId });
        return this.client.putMultiPart<BaseResponseWrapper<any>>(fullPath, dataToPost);
    }
    removeAdvisor(userId: string): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.RemoveAdvisor, { id: userId });
        return this.client.put<BaseResponseWrapper<any>>(path);
    }
    getCourierCompanyList(): Observable<CourierCompanyiLstResponse[]> {
        const path = this.createUrlWithParams(Endpoints.CourierCompaniesList);
        return this.client.get<CourierCompanyiLstResponse[]>(path);
    }
    insertCourierCompany(request: any): Observable<any> {
        const json = {
            ...request
        }
        const path = this.createUrlWithParams(Endpoints.UserCourierCompanyInsert);
        return this.client.post<any>(path, JSON.stringify(json))
    }
    updateCourierCompany(request: any): Observable<any> {

        const json = {
            ...request
        }
        const path = this.createUrlWithParams(Endpoints.UserCourierCompanyUpdate);
        return this.client.put<BaseResponseWrapper<any>>(path, JSON.stringify(json));
    }
    deleteCourierCompany(_id: any): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.UserCourierCompanyDelete, { id: _id })
        return this.client.delete<BaseResponseWrapper<any>>(path)
    }
    multipleGetCourierCompany(_userId: any, _companyId: any = null): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.UserCourierCompanyMultipleGet, { userId: _userId, companyId: _companyId })
        return this.client.get<BaseResponseWrapper<any>>(path);
    }
    createETransformationAccount(_userId: string): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.CreateETransformationAccount, { userId: _userId });
        return this.client.put<BaseResponseWrapper<any>>(path);
    }

    agentReport(_monthFilter: string, _isActive: boolean): Observable<any> {
        const fullPath = this.createUrlWithParams(Endpoints.AgentReport, { monthFilter: _monthFilter, isActive: _isActive });
        
        console.log("agent report request parameters monthFilter: ", _monthFilter);
        return this.client.get<BaseResponseWrapper<any>>(fullPath);
    }

    getAgentExcelExport(
        params = new UserSearchParams()
    ): Observable<any> {
        let fullPath = this.createUrlWithParams(Endpoints.AgentExcelExport);

        Object.keys(params).forEach(x => {
            if (params[x] !== undefined && params[x] !== null) {
                const qParam = `${x}=${params[x]}&`;
                fullPath += qParam;
            }
        });

        if (fullPath.endsWith('&')) fullPath = fullPath.slice(0, -1);
        return this.client.get(fullPath, true);
    }
    getUserByTaxNo(_taxNo: string, _agentId: any = null): Observable<any> {
        const path = this.createUrlWithParams(Endpoints.UserByTaxNo, { taxNo: _taxNo, agentId: _agentId })
        return this.client.get<BaseResponseWrapper<any>>(path);

    }
}
